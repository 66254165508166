import { Button, H1, P, Span } from '@aurelius/components'
import { Box } from '@chakra-ui/react'
import Link from 'next/link'
import { Logo } from '@aurelius/icons'
import { NextPage } from 'next'

const Page: NextPage = () => {
  return (
    <Box
      display="flex"
      margin="0 auto"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box textAlign="center">
        <Logo marginBottom="24px" height={100} width={100} />
        <H1 marginBottom="100px">Aurelius</H1>
      </Box>
      <P marginBottom="40px" textAlign="center">
        <Span fontSize={32} margin="0 auto">
          🤔
        </Span>
        <br />
        <br />
        Page Not Found
      </P>
      <Link href="/projects">
        <Button type="button">Go back to Aurelius</Button>
      </Link>
    </Box>
  )
}
Page.displayName = '404'
export default Page
